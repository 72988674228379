import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from '@providers/auth';
import { CommonHash } from '@types';
import { useNavigate } from 'react-router-dom';
import Notification from '@components/notification/notification';
let API_URL = 'http://localhost:3000';
if (import.meta.env.VITE_DEPLOY_ENV === 'main') {
  // API_URL = 'https://api.tufacturador.cl';
  API_URL = 'https://api.tufacturador.plaglabs.com';
} else if (import.meta.env.VITE_DEPLOY_ENV === 'cert') {
  API_URL = 'https://facturador.plaglabs.com';
}
export function useLogin() {
  const queryClient = useQueryClient();
  const { setSession } = useAuth();
  const navigate = useNavigate();
  return useMutation((user: CommonHash) => axios.post(`${API_URL}/login`, user), {
    onSuccess: (response) => {
      const authorizationToken = response.headers.authorization.split(' ')[1];
      setSession({
        token: authorizationToken,
        user: { id: response.data.id, email: response.data.email },
        activeAccount: response.data.active_account,
        status: 'loggedIn',
      });
      Notification({ type: 'success', message: 'Ha iniciado sesión correctamente' });

      queryClient.invalidateQueries(['user']);
      navigate('/emitidos');
    },
    onError: (error: AxiosError) => {
      let errorMsg;
      if (
        error.response &&
        typeof error.response.data === 'object' &&
        error.response.data !== null &&
        'message' in error.response.data
      ) {
        errorMsg = error.response.data.message;
      } else {
        errorMsg = 'Ha ocurrido un error';
      }
      Notification({ type: 'error', message: errorMsg as string });
    },
  });
}

export function useLogout() {
  const queryClient = useQueryClient();
  const { session, setSession } = useAuth();
  const navigate = useNavigate();
  return useMutation(
    () =>
      axios.delete(`${API_URL}/logout`, {
        headers: {
          ...(session?.token && { Authorization: `Bearer ${session.token}` }),
        },
      }),
    {
      onSuccess: () => {
        setSession({
          status: 'loggedOut',
          token: undefined,
          user: undefined,
          activeAccount: undefined,
        });
        navigate('/');
        Notification({ type: 'success', message: 'Ha cerrado la sesión correctamente' });
        queryClient.invalidateQueries(['user']);
      },
    },
  );
}
