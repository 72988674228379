export function capitalizeFirstLetter(text?: string) {
  if (text === undefined || text === null) return text;
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function mapShortDTENameToDTENumberType(text: string) {
  switch (text) {
    case 'ElectronicInvoice':
      return 33;
    case 'ElectronicExemptInvoice':
      return 34;
    case 'ElectronicReceipt':
      return 39;
    case 'ElectronicExemptReceipt':
      return 41;
    case 'ElectronicSettlement':
      return 43;
    case 'ElectronicPurchaseInvoice':
      return 46;
    case 'ElectronicDispatchGuide':
      return 52;
    case 'ElectronicDebitNote':
      return 56;
    case 'ElectronicCreditNote':
      return 61;
    case 'ElectronicExportInvoice':
      return 110;
    case 'ElectronicExportDebitNote':
      return 111;
    case 'ElectronicExportCreditNote':
      return 112;
    default:
      undefined;
  }
}
