import request from '@utils/axios-utils';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { CommonHash, Account, ApiObject } from '@types';
import Notification from '@components/notification/notification';
import { useAuth } from '@providers/auth';

export function useUploadLogo() {
  const { session, setSession } = useAuth();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/accounts/logo`,
        method: 'PATCH',
        data,
      });
    },
    {
      onSuccess: (res: any) => {
        setSession({
          ...session,
          activeAccount: { ...(session.activeAccount as Account), logo: res.data.data.logo },
        });
        Notification({ type: 'success', message: 'Se ha cargado correctamente el logo' });
      },
    },
  );
}

export function useDeleteLogo() {
  const queryClient = useQueryClient();
  const { session, setSession } = useAuth();
  return useMutation(
    () => {
      return request({
        authorizationToken: session?.token,
        url: `/accounts/logo`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se ha eliminado el logo' });
        setSession({
          ...session,
          activeAccount: { ...(session.activeAccount as Account), logo: undefined },
        });
        queryClient.invalidateQueries('account');
      },
    },
  );
}

export function useAccount(uuid: string | undefined) {
  return useQuery<ApiObject<Account>>(['account', uuid], async () => {
    const response = await request({
      url: `/accounts/${uuid}/unauthenticated`,
      method: 'GET',
    });
    return response.data;
  });
}
