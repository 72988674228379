import { useNavigate } from 'react-router-dom';
import { Modal, Button, Group } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import Notification from '@components/notification/notification';
import { useVoidDocumentMutation } from '@api/documents';
import { CommonHash } from '@types';
interface VoidDocumentProps {
  opened: boolean;
  close: () => void;
  row: CommonHash;
}

const validDate = (value: string, referenceDate: string) => {
  const valueDate = new Date(value);
  const referenceDateObj = new Date(referenceDate);

  const today = new Date();

  return valueDate >= referenceDateObj && valueDate <= today;
};
export const VoidDocumentModal = ({ opened, close, row }: VoidDocumentProps) => {
  const navigate = useNavigate();
  const { mutate: voidDocument } = useVoidDocumentMutation();
  const handleVoidDocument = () => {};

  const validation = (value: string) => {
    if (value !== '' && validDate(value, row.issue_date)) return null;
    if (value === '') return 'Este campo es requerido';
    if (new Date(value) > new Date()) return 'La fecha no puede ser posterior a hoy';
    return 'La fecha no puede ser anterior a la fecha del documento';
  };
  const form = useForm({
    initialValues: { issueDate: '' },
    validateInputOnChange: true,
    validateInputOnBlur: true,
    validate: {
      issueDate: (value) => validation(value),
    },
  });
  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} fw={600}>
            Anular {row?.dte_type} N° {row?.number}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              close();
              Notification({ type: 'information', message: 'Se está generando el documento' });
              voidDocument({ uuid: row.uuid as string, issueDate: values.issueDate });
              navigate('/emitidos');
            })}
          >
            <Group mb={30} justify="center">
              <DateInput
                withAsterisk
                valueFormat="YYYY-MM-DD"
                label="Fecha de emisión"
                placeholder="Fecha"
                {...form.getInputProps('issueDate')}
              />
            </Group>
            <Group justify="end">
              <Button onClick={close}>Cerrar</Button>
              <Button type="submit" onClick={handleVoidDocument}>
                Emitir
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    // <div className="font-sans">
    //   <Modal opened={open} ref={ref}>
    //     <Modal.Body className="flex flex-col justify-center gap-y-3 items-center">
    // <label htmlFor="date" className="block font-medium text-gray-900">
    //   Selecciona la fecha de emisión:
    // </label>
    // <input
    //   type="date"
    //   name="date"
    //   className="form-input rounded border border-solid	p-2"
    //   value={date}
    //   onChange={(event) => {
    //     setDate(event.target.value);
    //   }}
    // />
    //     </Modal.Body>
    //     <Modal.Actions>
    // <form method="dialog" className="flex gap-3">
    //   <Button className="btn-outline-primary" onClick={() => setOpen(!open)}>
    //     Cerrar
    //   </Button>
    //   <Button type="submit" onClick={handleVoidDocument} className="btn-primary">
    //     Emitir
    //   </Button>
    // </form>
    //     </Modal.Actions>
    //   </Modal>
    // </div>
  );
};
