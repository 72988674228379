import { Grid, Text, Stack, Group, NumberFormatter } from '@mantine/core';
import { format } from 'rut.js';
import { format as formatDate } from 'date-fns';
import { es } from 'date-fns/locale';
import { CommonHash } from '@types';
import { exportType, receiptType, purchaseType } from '../../form/formUtils';
import {
  MEASUREMENTUNITOPTIONS,
  EXPORTATIONSALESMODEOPTIONS,
  EXPORTATIONSALESCLAUSEOPTIONS,
  TRANSPORTROUTES,
  TRANSFERINDEXOPTIONS,
  TRANSFERCODEOPTIONS,
  EXPORTATIONPAYMENTOPTIONS,
} from '@constants';

function Receiver({ company, document }: { company: CommonHash; document: CommonHash }) {
  if (exportType(document.type)) {
    return exportTypeReceiver({ company, document });
  } else if (document.type === 'ElectronicDispatchGuide') {
    return dispatchGuideReceiver({ company, document });
  } else {
    return (
      <Stack mb={20}>
        <Group justify="space-between" w="100%">
          <div>
            <Text fz={12} mb={10} mt={10}>
              {formatDate(document.issue_date, "d 'de' MMMM 'de' yyyy", { locale: es })}
            </Text>
            <Grid miw={300}>
              <Grid.Col span={3} py={5}>
                <Text fw={800} fz={12}>
                  R.U.T
                </Text>
              </Grid.Col>
              <Grid.Col span={9} py={5} px={8}>
                <Text fz={12}>: {company.tax_id ? format(company.tax_id) : ''}</Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={3} py={5}>
                <Text fw={800} fz={12}>
                  SEÑOR(ES)
                </Text>
              </Grid.Col>
              <Grid.Col span={9} py={5} px={8}>
                <Text fz={12}>
                  :{' '}
                  {purchaseType(document.type)
                    ? document.company_additional_info
                    : company.legal_name}
                </Text>
              </Grid.Col>
            </Grid>
            {!receiptType(document.type) && (
              <Grid>
                <Grid.Col span={3} py={5}>
                  <Text fw={800} fz={12}>
                    GIRO
                  </Text>
                </Grid.Col>
                <Grid.Col span={9} py={5} px={8}>
                  <Text fz={12}>: {company.activity}</Text>
                </Grid.Col>
              </Grid>
            )}
            <Grid>
              <Grid.Col span={3} py={5}>
                <Text fw={800} fz={12}>
                  DIRECCION
                </Text>
              </Grid.Col>
              <Grid.Col span={9} py={5} px={8}>
                <Text fz={12}>
                  : {company.address}, {company.commune}
                </Text>
              </Grid.Col>
            </Grid>
          </div>
        </Group>
      </Stack>
    );
  }
}

export default Receiver;

function exportTypeReceiver({ company, document }: { company: CommonHash; document: CommonHash }) {
  return (
    <Stack mb={50}>
      <Text fz={12} mb={10} mt={10}>
        {formatDate(document.issue_date, "d 'de' MMMM 'de' yyyy", { locale: es })}
      </Text>
      <Grid miw={300} w={'100%'}>
        <Grid.Col span={2} py={1}>
          <Text fw={800} fz={12}>
            R.U.T
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>: {company.tax_id ? format(company.tax_id) : ''}</Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            Forma de Pago
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              EXPORTATIONPAYMENTOPTIONS.find(
                (item) => item.value === document.exportation_payment_type,
              )?.label
            }
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Señor(es)
          </Text>
        </Grid.Col>
        <Grid.Col span={10} py={1} px={8}>
          <Text fz={12}>: {document.company_additional_info}</Text>
        </Grid.Col>

        <Grid.Col span={2} py={1}>
          <Text fw={800} fz={12}>
            Dirección
          </Text>
        </Grid.Col>
        <Grid.Col span={10} py={1} px={8}>
          <Text fz={12}>: {company.address}</Text>
        </Grid.Col>

        <Grid.Col span={2} py={1}>
          <Text fw={800} fz={12}>
            Tipo Moneda
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>: {document.currency}</Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            Tasa de Cambio
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :
            <NumberFormatter
              value={document.exchange_rate}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
            />
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Mod. de Venta
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              EXPORTATIONSALESMODEOPTIONS.find(
                (item) =>
                  item.value === String(document.transport_attributes.exportation_sales_mode),
              )?.label
            }
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            Vía Transporte
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              TRANSPORTROUTES.find(
                (item) => item.value === String(document.transport_attributes.transport_route),
              )?.label
            }
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} pe={0}>
          <Text fw={800} fz={12}>
            Claus. De Venta
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              EXPORTATIONSALESCLAUSEOPTIONS.find(
                (item) =>
                  item.value === String(document.transport_attributes.exportation_sales_clause),
              )?.label
            }
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            T. Claus. de Venta
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :
            <NumberFormatter
              value={document.transport_attributes.exportation_sales_clause_amount}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
            />
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Tara
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :
            <NumberFormatter
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              value={document.transport_attributes.tare}
            />
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            U. de Med. Tara
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              MEASUREMENTUNITOPTIONS.find(
                (item) => item.value === String(document.transport_attributes.tare_unit),
              )?.label
            }
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Peso Bruto
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            <NumberFormatter
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              value={document.transport_attributes.gross_weight}
            />
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            U. de Med. P. Bruto
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1}>
          <Text fz={12}>
            :{' '}
            {
              MEASUREMENTUNITOPTIONS.find(
                (item) => item.value === String(document.transport_attributes.gross_weight_unit),
              )?.label
            }
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Peso Neto
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1}>
          <Text fz={12}>
            :
            <NumberFormatter
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              value={document.transport_attributes.net_weight}
            />
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            U. de Med. P. Neto
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              MEASUREMENTUNITOPTIONS.find(
                (item) => item.value === String(document.transport_attributes.net_weight_unit),
              )?.label
            }
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Total Bultos
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :
            <NumberFormatter
              thousandSeparator="."
              decimalSeparator=","
              value={document.transport_attributes.total_packages}
            />
          </Text>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}

function dispatchGuideReceiver({
  company,
  document,
}: {
  company: CommonHash;
  document: CommonHash;
}) {
  return (
    <Stack mb={50}>
      <Text fz={12} mb={10} mt={10}>
        {formatDate(document.issue_date, "d 'de' MMMM 'de' yyyy", { locale: es })}
      </Text>
      <Grid miw={300} w={'100%'}>
        <Grid.Col span={2} py={1}>
          <Text fw={800} fz={12}>
            R.U.T
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>: {company.tax_id ? format(company.tax_id) : ''}</Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            Tipo de Traslado
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              TRANSFERINDEXOPTIONS.find((item) => item.value === String(document.transfer_index))
                ?.label
            }
          </Text>
        </Grid.Col>

        <Grid.Col span={2} py={1} px={8}>
          <Text fw={800} fz={12}>
            Señor(es)
          </Text>
        </Grid.Col>
        <Grid.Col span={4} py={1} px={8}>
          <Text fz={12}>: {document.company_additional_info}</Text>
        </Grid.Col>
        <Grid.Col span={2} py={1} px={0}>
          <Text fw={800} fz={12}>
            Código Emisor de Traslado Excep.
          </Text>
        </Grid.Col>
        <Grid.Col span={3} py={1} px={8}>
          <Text fz={12}>
            :{' '}
            {
              TRANSFERCODEOPTIONS.find((item) => item.value === String(document.transfer_code))
                ?.label
            }
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={5}>
          <Text fw={800} fz={12}>
            Giro
          </Text>
        </Grid.Col>
        <Grid.Col span={10} py={5} px={8}>
          <Text fz={12}>: {company.activity}</Text>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={2} py={5}>
          <Text fw={800} fz={12}>
            Dirección
          </Text>
        </Grid.Col>
        <Grid.Col span={9} py={5} px={8}>
          <Text fz={12}>
            : {company.address}, {company.commune}
          </Text>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}
